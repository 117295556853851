







import Vue from "vue";
import Navbar from "./components/Navbar.vue";

export default Vue.extend({
  components: { Navbar },
});
