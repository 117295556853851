


















































































































import Vue from "vue";
export default Vue.extend({
  computed: {
    spanish(): boolean {
      return this.$i18n.locale == "es";
    },
  },
});
