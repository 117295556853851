










export default {
  name: "LocaleSelector",
  data(): Record<string, unknown> {
    return { langs: ["en", "es"] };
  }
};
