





















































import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);
import LocaleSelector from "../LocaleSelector.vue";

import eventBus from "../event-bus";
import Help from "./Help.vue";

export default Vue.extend({
  components: { Help, LocaleSelector },
  data() {
    return { helpModalActive: false };
  },

  methods: {
    showControlPanel(): void {
      eventBus.$emit("control-panel:show");
    },

    showHelp(): void {
      if (this.$route.path === "/") {
        // TODO: We should just be able to display the help modal
        // as an element within the navbar. However, when we do that,
        // the map component obscures the help modal, so there is a
        // special implementation of the help modal in Map.vue
        // triggered by this event.
        eventBus.$emit("show:help");
      } else {
        this.helpModalActive = true;
      }
    },
  },
});
